<template>
  <div>
    <div v-show="false" class="pt--4" id="alertsticky">
      <CRow>
        <CCol col="12">
          <CAlert color="warning" >
            <center><CIcon :height="25" name="cil-bullhorn" />โปรดอย่าลืมส่งข้อมูลรายงานการคุมสอบ</center>
          </CAlert>
        </CCol>
      </CRow>
    </div>
    <div>
      <CRow class="ml-5 mr-5">
        <CCol >
          <div class="text-left">
            <CLink to="/events" style="color: #778899;" ><i class="fas fa-backward"></i> กลับไปยังก่อนหน้า</CLink> 
          </div>
        </CCol>
      </CRow>
      <div class="mt-5">
      </div>
      <CRow class="mt-5 ml-5 mr-5">
      <CCol >
         <div class="mt-3">  
          <p style="font-size: 48px; font-weight: bolder;">
            {{ this.eventName }}
          </p>
        </div>
      </CCol>
      
      </CRow>
      <CRow class="mt-5 ml-5 mr-5">
        <CCol col="10" xl="10" v-if="!eventStart">
          <div class="pt--2">
            <div class="pr-2 pb-2" style="display: inline-block;">
              <CButton
                @click="createEventAvailable(eventID)"
                color="success"
                v-c-popover="{
                  header: 'เริ่มสอบ',
                  content: 'กด Start เพื่อเริ่มดูข้อมูลนักศึกษาแบบ Realtime',
                  active: !eventStart,
                  placement: 'top-end',
                }"
              >
                <CIcon :height="50" name="cil-media-play" />
                Start Event
              </CButton>
            </div>
            <div class="animated pr-2 pb-2" style="display: inline-block;">
              <CButton @click="addStudent" color="zoomblue">
                <CIcon :height="50" name="cil-user-plus" />
                เพิ่มรายชื่อนักศึกษา
              </CButton>
            </div>
            <div class="animated pr-2 pb-2" style="display: inline-block;">
              <CButton @click="patchStudent(eventID)" color="pastelpurple2">
                <CIcon name="cil-sync" />
                อัพเดทรายชื่อนักศึกษา
              </CButton>
            </div>
            <div class="animated pr-2 pb-2" style="display: inline-block;">
              <CButton @click="exportXlsx" color="excelgreen">
                <i class="fas fa-file-excel"></i>
                ดาวน์โหลดรายงานการสอบ
              </CButton>
            </div>
            <div class="animated" style="display: inline-block;" v-show="this.eventData.location_enable">
              <CButton @click="popup" color="pastelpurple2">
                <i class="fas fa-map-marked-alt"></i>
                ดูแผนที่
              </CButton>
            </div>
          </div>
        </CCol>
        <CCol col="10" xl="10" v-if="eventStart">
          <div class="pt--2">
            <div class="pr-2 pb-2" style="display: inline-block;">
              <CButton @click="confirmDeleteEventAvailable(eventID)" color="danger">
                <CIcon :height="50" name="cil-media-stop" />
                End Event
              </CButton>
            </div>
            <div class="animated pr-2 pb-2" style="display: inline-block;">
              <CButton @click="addStudent" color="zoomblue">
                <CIcon :height="50" name="cil-user-plus" />
                เพิ่มรายชื่อนักศึกษา
              </CButton>
            </div> 
            <div class="animated pr-2 pb-2" style="display: inline-block;">
              <CButton @click="patchStudent(eventID)" color="pastelpurple2">
                <CIcon name="cil-sync" />
                อัพเดทรายชื่อนักศึกษา
              </CButton>
            </div>
            <div class="animated pr-2 pb-2" style="display: inline-block;">
              <CButton @click="exportXlsx" color="excelgreen">
                <i class="fas fa-file-excel"></i>
                ดาวน์โหลดรายงานการสอบ
              </CButton>
            </div>
           
            <div class="animated" style="display: inline-block;" v-show="this.eventData.location_enable">
              <CButton @click="popup" color="pastelpurple2">
                <i class="fas fa-map-marked-alt"></i>
                ดูแผนที่
              </CButton>
            </div>
          </div>
        </CCol>
        <CCol col="2" xl="2">
          <div class="pt--2 card-header-actions">
            <CDropdown color="light p-2" placement="bottom-end" add-menu-classes="pt-0">
              <template #toggler-content>
                <CIcon name="cil-settings" />
              </template>
              <CDropdownHeader tag="div" class="text-center" color="light">
                <strong>{{ getRoleStatus(accRole) }}</strong>
              </CDropdownHeader>
              <div v-if="accRole === 'owner' || accRole === 'assistant' || accRole === 'admin'">
                <CDropdownItem @click="addCollaborator"><CIcon name="cil-user-plus" /> เพิ่มผู้ดูแล</CDropdownItem>
              </div>
              <div v-if="accRole === 'owner' || accRole === 'assistant' || accRole === 'admin'">
                <CDropdownItem @click="addStudent"><CIcon name="cil-user-plus" /> เพิ่มรายชื่อนักศึกษา</CDropdownItem>
              </div>
              <div v-if="accRole === 'owner' || accRole === 'assistant' || accRole === 'admin'">
                <CDropdownItem @click="beforeShowSettingsModal"><CIcon name="cil-settings" /> ตั้งค่า</CDropdownItem>
              </div>
              <CDropdownItem @click="eventInfo"><CIcon name="cil-info" /> Info</CDropdownItem>

              <div v-if="accRole === 'owner' || accRole === 'admin'">
                <CDropdownHeader tag="div" class="text-center" color="light">
                  <strong class="text-danger">Danger Zone</strong>
                </CDropdownHeader>
                <CDropdownItem @click="confirmDelete(eventID)"><CIcon name="cil-x" /> Delete</CDropdownItem>
              </div>
            </CDropdown>
          </div>
        </CCol>
      </CRow>
      <CRow class="ml-5 mr-5">
        <CCol col="12" xl="12">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol lg="9" md="7" class="text-center">
                  <CInput label="Link สำหรับนักศึกษา check-in" type="text" :value="`${studentDomain}student/code/${eventID}`" :readonly="true" addInputClasses="py-4">
                    <template #append>
                      <CButton v-c-tooltip="{ content: copyText }" type="button" color="dark" v-clipboard:copy="`${studentDomain}student/code/${eventID}`"><CIcon name="cil-clipboard"/></CButton>
                    </template>
                  </CInput>
                </CCol>
                <CCol lg="3" md="5" class="text-center">
                  <CInput
                    label="รหัส pincode สำหรับการเข้าสอบ"
                    type="text"
                    :value="`${eventData['pincode'] ? eventData['pincode'] : 'ไม่พบข้อมูล'}`"
                    :readonly="true"
                    addInputClasses="py-4 text-increase-size"
                  >
                    <template #label>
                      <h6 class="pb-1">
                        รหัส pincode สำหรับการเข้าสอบ <a @click="showPincodeHelp"><i class="far fa-question-circle"></i></a>
                      </h6>
                    </template>
                    <template #append>
                      <CButton v-c-tooltip="{ content: copyText }" type="button" color="dark" v-clipboard:copy="`${eventData['pincode'] ? eventData['pincode'] : 'ไม่พบข้อมูล'}`"
                        ><CIcon name="cil-clipboard"
                      /></CButton>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow class="ml-5 mr-5">
        <CCol col="12">
          <transition name="fade">
            <CCard>
              <CCardHeader>
                กราฟข้อมูลการเช็คอิน
                <div class="card-header-actions">
                  <CLink class="card-header-action btn-minimize" @click="isCollapsed.checkIn = !isCollapsed.checkIn">
                    <CIcon :name="`cil-chevron-${isCollapsed.checkIn ? 'top' : 'bottom'}`" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="isCollapsed.checkIn" :duration="400">
                <CCardBody>
                  <CRow>
                    <CCol>
                      <CProgress class="mt-1" :max="barChart.checkIn.all" show-value>
                        <CProgressBar :value="barChart.checkIn.checkOut" color="gradient-primary" />
                        <CProgressBar :value="barChart.checkIn.checkIn" color="gradient-success" />
                        <CProgressBar :value="barChart.checkIn.notCheckIn" color="gradient-warning" />
                      </CProgress>
                    </CCol>
                  </CRow>
                  <CRow class="pt-1">
                    <CCol class="text-center">
                      <CBadge color="primary" class="mfs-auto">{{ barChart.checkIn.checkOut }}</CBadge
                      >&nbsp;เช็คเอาท์แล้ว&nbsp;&nbsp;&nbsp; <CBadge color="success" class="mfs-auto">{{ barChart.checkIn.checkIn }}</CBadge
                      >&nbsp;เช็คอินแล้ว&nbsp;&nbsp;&nbsp; <CBadge color="warning" class="mfs-auto">{{ barChart.checkIn.notCheckIn }}</CBadge
                      >&nbsp;ยังไม่เช็คอิน
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCollapse>
            </CCard>
          </transition>
        </CCol>
        <CCol col="12" v-show="!this.noZoomInEvent">
          <transition name="fade">
            <CCard>
              <CCardHeader>
                กราฟข้อมูลการเข้า zoom
                <div class="card-header-actions">
                  <CLink class="card-header-action btn-minimize" @click="isCollapsed.zoom = !isCollapsed.zoom">
                    <CIcon :name="`cil-chevron-${isCollapsed.zoom ? 'top' : 'bottom'}`" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="isCollapsed.zoom" :duration="400">
                <CCardBody>
                  <CRow>
                    <CCol>
                      <CProgress class="mt-1" :max="barChart.zoom.all" show-value>
                        <CProgressBar :value="barChart.zoom.endZoom" color="gradient-primary" />
                        <CProgressBar :value="barChart.zoom.inZoom" color="gradient-success" />
                        <CProgressBar :value="barChart.zoom.notInZoom" color="gradient-warning" />
                        <CProgressBar :value="barChart.zoom.disConnect" color="gradient-danger" />
                      </CProgress>
                    </CCol>
                  </CRow>
                  <CRow class="pt-1">
                    <CCol class="text-center">
                      <CBadge color="primary" class="mfs-auto">{{ barChart.zoom.endZoom }}</CBadge
                      >&nbsp;เสร็จสิ้น&nbsp;&nbsp;&nbsp; <CBadge color="success" class="mfs-auto">{{ barChart.zoom.inZoom }}</CBadge
                      >&nbsp;เข้าร่วมแล้ว&nbsp;&nbsp;&nbsp; <CBadge color="warning" class="mfs-auto">{{ barChart.zoom.notInZoom }}</CBadge
                      >&nbsp;ยังไม่ได้เข้าร่วม&nbsp;&nbsp;&nbsp; <CBadge color="danger" class="mfs-auto">{{ barChart.zoom.disConnect }}</CBadge
                      >&nbsp;หลุด/ผิดปกติ
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCollapse>
            </CCard>
          </transition>
        </CCol>
        <CCol col="12">
          <transition name="fade">
            <CCard>
              <CCardHeader>
                กราฟข้อมูลการส่งข้อสอบ
                <div class="card-header-actions">
                  <CLink class="card-header-action btn-minimize" @click="isCollapsed.exam = !isCollapsed.exam">
                    <CIcon :name="`cil-chevron-${isCollapsed.exam ? 'top' : 'bottom'}`" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="isCollapsed.exam" :duration="400">
                <CCardBody>
                  <CRow>
                    <CCol>
                      <CProgress class="mt-1" :max="barChart.exam.all" show-value>
                        <CProgressBar :value="barChart.exam.success" color="gradient-primary" />
                        <CProgressBar :value="barChart.exam.inprogress" color="gradient-success" />
                        <CProgressBar :value=" barChart.exam.notstart" color="gradient-warning" />
                      </CProgress>
                    </CCol>
                  </CRow>
                  <CRow class="pt-1">
                    <CCol class="text-center">
                      <CBadge color="primary" class="mfs-auto">{{ barChart.exam.success }}</CBadge
                      >&nbsp;ส่งข้อสอบแล้ว&nbsp;&nbsp;&nbsp; 
                      <CBadge color="success" class="mfs-auto">{{ barChart.exam.inprogress }}</CBadge
                      >&nbsp;กำลังทำข้อสอบ&nbsp;&nbsp;&nbsp; 
                      <CBadge color="warning" class="mfs-auto">{{ barChart.exam.notstart}}</CBadge
                      >&nbsp;ยังไม่ส่งข้อสอบ
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCollapse>
            </CCard>
          </transition>
        </CCol>
      </CRow>
      <CRow class="ml-5 mr-5">
        <CCol col="12" xl="12">
          <CCard>
            <CCardHeader>
              {{ eventName }}
              <CBadge v-if="eventStart" color="success" class="animated pulse">Online</CBadge>
              <CBadge v-if="!eventStart" color="secondary">Offline</CBadge>
             
              <div class="card-header-actions">
                
                <a @click="studentsReportClicked()">
                  <notification-bell
                    :size="25"
                    :count="notificationCounter"
                    :upperLimit="50"
                    counterLocation="upperRight"
                    counterStyle="roundRectangle"
                    counterBackgroundColor="#e55353"
                    counterTextColor="#FFFFFF"
                    iconColor="#828899"
                  />
                </a>
              </div>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                hover
                stripe
                :table-filter="{ placeholder: 'คำที่ต้องการค้นหา...', label: 'Search : ' }"
                sorter
                border
                :items-per-page-select="{ values: [50, 100, 200, 300, 1000] }"
                :items="itemsExam"
                :responsive="true"
                :fields="fieldsExam"
                :items-per-page="itemPerPage"
                clickable-rows
                @row-clicked="studentDetail"
                :active-page="activePage"
                :sorterValue="sorterRemember"
                :pagination="{ doubleArrows: false, align: 'center' }"
                @page-change="pageChange"
                @pagination-change="paginationChange"
                @update:sorter-value="sortChange"
              >
              <!-- @row-clicked="studentDetail" -->
                <template #index="data">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                </template>
                <template #state_zoom="data">
                  <td>
                    <CBadge :color="getZoomBadge(data.item.state_zoom)">
                      {{ data.item.state_zoom }}
                    </CBadge>
                  </td>
                </template>
                <template v-slot:state_exam_status="data">
                  <td>
                    <CBadge :color="getExamBadge(data.item.state_exam_status)">
                      {{ data.item.state_exam_status }}
                    </CBadge>
                  </td>
                </template>
                <div v-bind:key="name" v-for="(value, name) in fieldsExam">{{ name }}: {{ value }}</div>
                <template v-for="(value, name) in newFieldExam" v-slot:[value.key]="data">
                  <td v-bind:key="[data.item.state_exam_quiz_2491, name].join('-')">
                    <CBadge :color="getExamBadge(data.item[value.key])">
                      {{ data.item[value.key] }}
                    </CBadge>
                  </td>
                </template>
                <template #state_checkin="data">
                  <td>
                    <CBadge :color="getCheckInBadge(data.item.state_checkin)"> {{ data.item.state_checkin }} </CBadge><br />
                  </td>
                </template>
                <template #checkin_ts="data">
                  <td>
                    {{ data.item.checkin_ts }}
                  </td>
                </template>
                <template #studentid="data">
                  <td v-if="data.item.studentid">
                    {{ data.item.studentid }}
                  </td>
                  <td v-else>
                    ไม่พบข้อมูล
                  </td>
                </template>
                <template #name_TH="data">
                  <td v-if="data.item.name_TH !== 'undefined undefined'">
                    {{ data.item.name_TH }}
                  </td>
                  <td v-else>
                    ไม่พบข้อมูล
                  </td>
                </template>
                <template #cmuitaccount="data">
                  <td v-if="data.item.cmuitaccount !== undefined ">
                    {{ data.item.cmuitaccount }}
                  </td>
                  <td v-else>
                    ไม่พบข้อมูล
                  </td>
                </template>
                <template #note>
                  <td>
                    <textarea style="width: 100px"></textarea>
                  </td>
                </template>
                <template #setting="data">
                  <td class="py-2">
                    <CButton color="danger" variant="outline" square size="sm" @click="deleteStudent(data.item)">
                      ลบ
                    </CButton>
                  </td>
                </template>
              </CDataTable>
              <div class="center-btn" v-if="!eventStart"></div>
              <div class="center-text" v-if="!eventStart">
                <CButton @click="createEventAvailable(eventID)" color="success" size="lg" class="xl-btn">
                  <i class="fas fa-play"></i>
                  Start Event
                </CButton>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <!--<userdetail />-->
    <!-- Modal -->
    <CModal title="Report" size="lg" :show.sync="studentsReportModal">
      <CDataTable
        hover
        stripe
        :table-filter="{ placeholder: 'คำที่ต้องการค้นหา...', label: 'Search : ' }"
        sorter
        border
        items-per-page-select
        :items="itemsReport"
        :fields="fieldsReport"
        :items-per-page="5"
        :active-page="activePage"
        :sorterValue="{ column: 'timestamp', asc: false }"
        :pagination="{ doubleArrows: false, align: 'center' }"
        @page-change="pageChange"
      >
        <template #state_zoom="data">
          <td>
            <CBadge :color="getZoomBadge(data.item.state_zoom)">
              {{ data.item.state_zoom }}
            </CBadge>
          </td>
        </template>
        <template #state_exam="data">
          <td>
            <CBadge :color="getExamBadge(data.item.state_exam)">
              {{ data.item.state_exam }}
            </CBadge>
          </td>
        </template>
        <template #state_checkin="data">
          <td>
            <CBadge :color="getCheckInBadge(data.item.state_checkin)">
              {{ data.item.state_checkin }}
            </CBadge>
          </td>
        </template>
        <template #timestamp="data">
          <td>
            {{ new Date(parseInt(data.item.timestamp) * 1000).toLocaleString("th-TH") }}
          </td>
        </template>
      </CDataTable>
      <template #footer>
        <CButton @click="studentsReportModal = false" color="secondary">Close</CButton>
      </template>
    </CModal>
    <!-- Modal Not found Event Component -->
    <CModal title="ไม่พบรายชื่อของท่าน" :show.sync="showStudentPhoto">
      <img :src="`data:image/jpeg;base64, ${studentPhoto}`" />
      <template #footer>
        <CButton @click="showStudentPhoto = false" color="secondary">Close</CButton>
      </template>
    </CModal>
    <!-- Modal Send Exam result Component -->
    <CModal title="ส่งข้อมูลสรุปการสอบ" :show.sync="showSendExamResultModal" size="lg">
      <center><h2>รายงานการคุมสอบ</h2></center>
      <table class="table">
        <tbody>
          <tr>
            <td>
              <CRow>
                <CCol class="text-right" col="6">
                  <b>มาสอบ</b>
                </CCol>
                <CCol col="3">
                  {{ reportStatusList.ok.length }}
                </CCol>
                <CCol col="3">
                  <CButton size="sm" @click="showCollapseDetail.ok = !showCollapseDetail.ok" color="outline-primary">{{ showCollapseDetail.ok ? "Hide" : "Show" }} detail</CButton><br />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <CCollapse :show="showCollapseDetail.ok" :duration="400" class="pt-4">
                    <CCard body-wrapper>
                      <CDataTable
                        hover
                        stripe
                        :table-filter="{ placeholder: 'ค้นหา...', label: ' ' }"
                        sorter
                        items-per-page-select
                        :items="reportStatusList.ok"
                        :fields="fieldsConcludeReport"
                        :items-per-page="5"
                        :active-page="activePage"
                        :pagination="{ doubleArrows: false, align: 'center' }"
                        @page-change="pageChange"
                      >
                        <template #change="data">
                          <td>
                            <center><CButton size="sm" @click="editStudentreport(data.item.email)" color="outline-danger">แก้ไข</CButton></center>
                          </td>
                        </template>
                      </CDataTable>
                    </CCard>
                  </CCollapse>
                </CCol>
              </CRow>
            </td>
          </tr>
          <tr>
            <td>
              <CRow>
                <CCol class="text-right" col="6">
                  <b>ขาดสอบ</b>
                </CCol>
                <CCol col="3">
                  {{ reportStatusList.absent.length }}
                </CCol>
                <CCol col="3">
                  <CButton size="sm" @click="showCollapseDetail.absent = !showCollapseDetail.absent" color="outline-primary">{{ showCollapseDetail.absent ? "Hide" : "Show" }} detail</CButton><br />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <CCollapse :show="showCollapseDetail.absent" :duration="400" class="pt-4">
                    <CCard body-wrapper>
                      <CDataTable
                        hover
                        stripe
                        :table-filter="{ placeholder: 'ค้นหา...', label: ' ' }"
                        sorter
                        items-per-page-select
                        :items="reportStatusList.absent"
                        :fields="fieldsConcludeReport"
                        :items-per-page="5"
                        :active-page="activePage"
                        :pagination="{ doubleArrows: false, align: 'center' }"
                        @page-change="pageChange"
                      >
                        <template #change="data">
                          <td>
                            <center><CButton size="sm" @click="editStudentreport(data.item.email)" color="outline-danger">แก้ไข</CButton></center>
                          </td>
                        </template>
                      </CDataTable>
                    </CCard>
                  </CCollapse>
                </CCol>
              </CRow>
            </td>
          </tr>
          <tr>
            <td>
              <CRow>
                <CCol class="text-right" col="6">
                  <b>มาสาย</b>
                </CCol>
                <CCol col="3">
                  {{ reportStatusList.late.length }}
                </CCol>
                <CCol col="3">
                  <CButton size="sm" @click="showCollapseDetail.late = !showCollapseDetail.late" color="outline-primary">{{ showCollapseDetail.late ? "Hide" : "Show" }} detail</CButton><br />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <CCollapse :show="showCollapseDetail.late" :duration="400" class="pt-4">
                    <CCard body-wrapper>
                      <CDataTable
                        hover
                        stripe
                        :table-filter="{ placeholder: 'ค้นหา...', label: ' ' }"
                        sorter
                        items-per-page-select
                        :items="reportStatusList.late"
                        :fields="fieldsConcludeReport"
                        :items-per-page="5"
                        :active-page="activePage"
                        :pagination="{ doubleArrows: false, align: 'center' }"
                        @page-change="pageChange"
                      >
                        <template #change="data">
                          <td>
                            <center><CButton size="sm" @click="editStudentreport(data.item.email)" color="outline-danger">แก้ไข</CButton></center>
                          </td>
                        </template>
                      </CDataTable>
                    </CCard>
                  </CCollapse>
                </CCol>
              </CRow>
            </td>
          </tr>
          <tr>
            <td>
              <CRow>
                <CCol class="text-right" col="6">
                  <b>ไม่มีชื่อ</b>
                </CCol>
                <CCol col="3">
                  {{ reportStatusList.NotFound.length }}
                </CCol>
                <CCol col="3">
                  <CButton size="sm" @click="showCollapseDetail.NotFound = !showCollapseDetail.NotFound" color="outline-primary">{{ showCollapseDetail.NotFound ? "Hide" : "Show" }} detail</CButton
                  ><br />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <CCollapse :show="showCollapseDetail.NotFound" :duration="400" class="pt-4">
                    <CCard body-wrapper>
                      <CDataTable
                        hover
                        stripe
                        :table-filter="{ placeholder: 'ค้นหา...', label: ' ' }"
                        sorter
                        items-per-page-select
                        :items="reportStatusList.NotFound"
                        :fields="fieldsConcludeReport"
                        :items-per-page="5"
                        :active-page="activePage"
                        :pagination="{ doubleArrows: false, align: 'center' }"
                        @page-change="pageChange"
                      >
                        <template #change="data">
                          <td>
                            <center><CButton size="sm" @click="editStudentreport(data.item.email)" color="outline-danger">แก้ไข</CButton></center>
                          </td>
                        </template>
                      </CDataTable>
                    </CCard>
                  </CCollapse>
                </CCol>
              </CRow>
            </td>
          </tr>
          <tr>
            <td>
              <CRow>
                <CCol col="12">
                  <CInput type="text" prepend="หมายเหตุ" required @update:value="detailFunc" />
                </CCol>
              </CRow>
            </td>
          </tr>
        </tbody>
        <center>
          <CButton size="lg" @click="sendExamResult()" color="success">ส่งรายงาน</CButton><br />
          <small><CIcon name="cil-lightbulb" />คุณสามารถส่งรายงานคุมสอบได้ไม่จำกัดโดยระบบจะยึดข้อมูลล่าสุดเป็นหลัก</small>
        </center>
      </table>

      <template #footer>
        <!-- <CButton @click="afterAcceptPolicy()" color="success">ยืนยัน</CButton> -->
        <CButton @click="showSendExamResultModal = false" color="secondary">ยกเลิก</CButton>
      </template>
    </CModal>
    <!-- Modal Not found Event Component -->
    <CModal title="เพิ่มผู้ดูแล" :show.sync="showAddCallaboratorModal" :closeOnBackdrop="false">
      <CRow>
        <CCol col="6">
          <CInput required :is-valid="collaboratorEmailValidator" placeholder="Email" />
        </CCol>
        <CCol col="4">
          <CSelect
            :options="[
              { value: 'owner', label: 'Host' },
              { value: 'assistant', label: 'Co-Host' },
              { value: 'observer', label: 'Observer' },
            ]"
            :is-valid="collaboratorRoleValidator"
            placeholder="Role"
          >
            <template #append>
              <CButton @click="showHelpAddRole()">
                <i class="far fa-question-circle"></i>
              </CButton>
            </template>
          </CSelect>
        </CCol>
        <CCol col="2">
          <CButton @click="submitCreateCollaborator" color="primary" :disabled="createCollaborator.activeAddBtn">
            เพิ่ม
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CDataTable
            hover
            stripe
            sorter
            :items="itemsCollaboratorList"
            :fields="fieldsCollaboratorList"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center' }"
            @page-change="pageChange"
          >
            <template #roles="data">
              <td>
                {{ getRoleStatus(data.item.roles[0]) }}
              </td>
            </template>
            <template #setting="data">
              <td class="py-2">
                <CButton color="danger" variant="outline" square size="sm" @click="deleteCollaborator(data.item)">
                  ลบ
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="showAddCallaboratorModal = false" color="secondary">Close</CButton>
      </template>
    </CModal>
    <CModal title="เพิ่มผู้ดูแล" :show.sync="showSettingsModal" :closeOnBackdrop="false" size="lg">
      <template #header>
        <CRow>
          <CCol>
            <CNav variant="pills">
              <a @click="settingsTab = 1"><CNavItem active>ตั้งค่า</CNavItem></a>
            </CNav>
          </CCol>
        </CRow>
      </template>
      <div v-if="settingsTab === 1">
        <CRow>
          <CCol col="12">
            <CAlert color="success" :show.sync="formSettings.showUpdateSuccess">
              อัพเดทเรียบร้อย รีโหลดในอีก 2 วินาที...
            </CAlert>
            <CAlert color="danger" :show.sync="formSettings.showUpdateFail">
              อัพเดทผิดพลาด
            </CAlert>
            <CAlert color="danger" :show.sync="formSettings.showUpdateZoomFail">
              อัพเดทZoomผิดพลาด
            </CAlert>
          </CCol>
        </CRow>
        <CRow class="mag-settting-row">
          <CCol sm="8">
            <h4>Zoom</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="2"></CCol>
          <CCol sm="1">
            <CSwitch class="mr-1" color="primary" shape="pill" @update:checked="useZoom" :checked="showZoomInput" />
          </CCol>
          <CCol sm="7">
            <h6 class="pt-1">ใช้ Zoom meeting ในการคุมสอบ</h6>
          </CCol>
          <CCol sm="2"></CCol>
        </CRow>
        <CCollapse :show="showZoomInput">
          <CRow>
            <CCol sm="2"></CCol>
            <CCol sm="8">
              <CInput type="url" prepend="Zoom Link" @update:value="eventZoomUrlValidator" :value="eventZoomUrl" />
            </CCol>
            <CCol sm="2"></CCol>
          </CRow>
        </CCollapse>
        <CRow class="mag-settting-row">
          <CCol sm="8">
            <h4>เวลาเช็คอิน</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="2"></CCol>
          <CCol sm="5">
            <b-input-group class="mb-2">
              <b-form-datepicker :value="formSettings.checkInDate" @input="formSettings.checkInDate = $event" locale="th" placeholder="--"> </b-form-datepicker>
            </b-input-group>
          </CCol>
          <CCol sm="3">
            <b-input-group class="mb-2">
              <b-form-input :value="formSettings.checkInTime" @input="formSettings.checkInTime = $event" type="text" placeholder="HH:mm:ss"></b-form-input>
              <b-input-group-append>
                <b-form-timepicker :value="formSettings.checkInTime" @input="formSettings.checkInTime = $event" button-only locale="th"></b-form-timepicker>
              </b-input-group-append>
            </b-input-group>
          </CCol>
          <CCol sm="2"></CCol>
        </CRow>
        <CRow class="mag-settting-row">
          <CCol sm="8">
            <h4>เวลาเริ่ม-สิ้นสุด</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="2"></CCol>
          <CCol sm="8">
            <b-input-group prepend="วันที่" class="mb-2">
              <b-form-datepicker :value="formSettings.eventDate" @input="formSettings.eventDate = $event" locale="th" placeholder="--"> </b-form-datepicker>
            </b-input-group>
          </CCol>
          <CCol sm="2"></CCol>
        </CRow>
        <CRow>
          <CCol sm="2"></CCol>
          <CCol sm="4">
            <b-input-group prepend="เริ่ม" class="mb-2">
              <b-form-input :value="formSettings.eventStartTime" @input="formSettings.eventStartTime = $event" type="text" placeholder="HH:mm:ss"></b-form-input>
              <b-input-group-append>
                <b-form-timepicker :value="formSettings.eventStartTime" @input="formSettings.eventStartTime = $event" button-only locale="th"></b-form-timepicker>
              </b-input-group-append>
            </b-input-group>
          </CCol>
          <CCol CCol sm="4">
            <b-input-group prepend="สิ้นสุด" class="mb-2">
              <b-form-input :value="formSettings.eventEndTime" @input="formSettings.eventEndTime = $event" type="text" placeholder="HH:mm:ss"></b-form-input>
              <b-input-group-append>
                <b-form-timepicker :value="formSettings.eventEndTime" @input="formSettings.eventEndTime = $event" button-only locale="th"></b-form-timepicker>
              </b-input-group-append>
            </b-input-group>
          </CCol>
          <CCol sm="2"></CCol>
        </CRow>
        <CRow class="mag-settting-row">
          <CCol sm="8">
            <h4>Quiz / Assignment</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="3" style="text-align:right;color:red"><CIcon :height="25" name="cil-warning"/></CCol>
          <CCol sm="6">
            <h5 style="color:red">ไม่สามารถแก้ไขหรือตั้งค่าเพิ่มเติม quiz หรือ assignment ได้</h5>
            <h5 style="color:red">แนะนำให้สร้าง event exam ใหม่</h5>
          </CCol>
          <CCol sm="3"></CCol>
        </CRow>
        <CRow class="mag-setting-row">
          <CCol col="12" style="text-align:center">
            <CButton class="update-settting" @click="submitUpdateCheckinDateTime" color="primary" size="lg">
              อัพเดท
            </CButton>
          </CCol>
        </CRow>
      </div>
      <div v-else-if="settingsTab === 2">
        <CRow>
          <CCol col="12">
            <CAlert color="success" :show.sync="formSettings.showUpdateSuccess">
              อัพเดทเวลาเรียบร้อย
            </CAlert>
            <CAlert color="danger" :show.sync="formSettings.showUpdateFail">
              อัพเดทเวลาผิดพลาด
            </CAlert>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="8">
            <h4>เวลาเริ่ม-สิ้นสุด</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="2"></CCol>
          <CCol sm="5">
            <b-input-group class="mb-2">
              <b-form-datepicker :value="formSettings.checkInDate" @input="formSettings.checkInDate = $event" locale="th" placeholder="--"> </b-form-datepicker>
            </b-input-group>
          </CCol>
          <CCol sm="3">
            <b-input-group class="mb-2">
              <b-form-input :value="formSettings.checkInTime" @input="formSettings.checkInTime = $event" type="text" placeholder="HH:mm:ss"></b-form-input>
              <b-input-group-append>
                <b-form-timepicker :value="formSettings.checkInTime" @input="formSettings.checkInTime = $event" button-only locale="th"></b-form-timepicker>
              </b-input-group-append>
            </b-input-group>
          </CCol>
          <CCol sm="2"></CCol>
        </CRow>
        <CRow>
          <CCol col="12" style="text-align:center">
            <CButton @click="submitUpdateStartEndDateTime" color="primary">
              อัพเดท
            </CButton>
          </CCol>
        </CRow>
      </div>
      <template #footer>
        <CButton @click="showSettingsModal = false" color="secondary">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import NotificationBell from "vue-notification-bell"
import examManangerApi from "../../services/apiServices"
import Swal from "sweetalert2"
import XLSX from "xlsx" 
import CryptoJS from 'crypto-js'
// import { ref } from "vue";

// const loading = ref(false);

export default {
  name: "EventDetail",
  components: {
    NotificationBell,
  },
  data() {
    return {
      eventID: "",
      reportID: "",
      studentsReportModal: false,
      eventName: "",
      eventData: [],
      itemsExam: [],
      itemsExamAllQuiz: [],
      itemsReport: [],
      srcExamFirst: "",
      srcExamList: [],
      currentQuiz: "",
      eventStart: false,
      barChart: {
        checkIn: {
          checkOut: 0,
          checkIn: 0,
          notCheckIn: 0,
          all: 100,
        },
        zoom: {
          inZoom: 0,
          notInZoom: 0,
          endZoom: 0,
          disConnect: 0,
          all: 100,
        },
        exam: {
          success: 0,
          inprogress: 0,
          notstart: 0,
          all: 100,
        },
      },
      SSE: {
        studentStatus: "",
        studentLeave: "",
      },
      fields: [{ key: "username", label: "Name", _classes: "font-weight-bold" }, { key: "registered" }, { key: "role" }, { key: "status" }],
      fieldsExam: [
        { key: "index", label: "No." },
        { key: "name_TH", label: "Name", _classes: "font-weight-bold" },
        { key: "studentid", label: "รหัสนักศึกษา" },
        { key: "cmuitaccount", label: "CMU Account" },
        { key: "state_checkin", label: "Current Status" },
        { key: "checkin_ts", label: "Recent Status Update" },
        { key: "state_zoom", label: "Zoom Status" },
      ],
      fieldsReport: [
        { key: "leave_user", label: "Email", _classes: "font-weight-bold" },
        { key: "state_zoom", label: "State" },
        { key: "leave_reason", label: "Reason" },
        { key: "timestamp", label: "time" },
      ],
      fieldsConcludeReport: [
        { key: "name", label: "ชื่อ", _classes: "font-weight-bold" },
        { key: "studentid", label: "รหัสนักศึกษา" },
        { key: "change", label: "เปลี่ยนแปลง" },
      ],
      
      activePage: 1,
      notificationCounter: 0,
      copyText: "Copy to clipboard",
      studentPhoto: "",
      showStudentPhoto: false,
      accRole: "user",
      noZoomInEvent: false,
      showSendExamResultModal: false,
      showCollapseDetail: {
        ok: false,
        absent: false,
        late: false,
        NotFound: false,
      },
      reportStatusList: {
        ok: [],
        absent: [],
        late: [],
        NotFound: [],
      },
      reportDetails: "",
      itemPerPage: localStorage.getItem("itemPerPage") ? parseInt(localStorage.getItem("itemPerPage")) : 50,
      studentDomain: process.env.VUE_APP_STUDENT_DOMAIN,
      testSlots: "state_exam_quiz_2491",
      newFieldExam: [],
      sorterRemember: localStorage.getItem("sortItemRemember") ? JSON.parse(localStorage.getItem("sortItemRemember")) : { column: null, desc: "false" },
      showAddCallaboratorModal: false,
      fieldsCollaboratorList: [
        { key: "email", label: "Email", _classes: "font-weight-bold" },
        { key: "roles", label: "Role" },
        { key: "setting", label: "", _style: "width:1%", sorter: false, filter: false },
      ],
      itemsCollaboratorList: [],
      createCollaborator: {
        email: "",
        role: "",
        activeAddBtn: true,
      },
      showSettingsModal: false,
      formSettings: {
        checkInDate: "",
        checkInTime: "",
        eventDate: "",
        eventStartTime: "",
        eventEndTime: "",
        showUpdateSuccess: false,
        showUpdateFail: false,
        showUpdateZoomSuccess: false,
        showUpdateZoomFail: false,
      },
      bootstrapValidate: {
        date: false,
        startTime: false,
        endTime: false,
      },
      callGetStudentsCount: 0,
      onCallingGetStudents: false,
      settingsTab: 1,
      showZoomInput: false,
      eventZoomUrl: "",
      // isCollapsed:true
      isCollapsed: {
        checkIn: true,
        zoom: true,
        exam: true,
      },
      temp_studentsLeave: [] , 
      threadCounter : 0 
    }
  },
  created() {
    this.eventID = this.$route.params.eventid
    let role = CryptoJS.AES.decrypt(localStorage.getItem("Admin"), process.env.VUE_APP_LOCALSTORAGE_KEY).toString(CryptoJS.enc.Utf8)
    if (role !== "super-admin") {
      this.checkIsCollaborator(this.eventID, localStorage.getItem("email"))
    } else {
      this.accRole = "admin"
    }
    //this.patchStudent(this.eventID)
    this.getEventById(this.eventID)

    this.getEventAvailableById(this.eventID)
    window.addEventListener("scroll", this.scrollFunction)
    this.getStudentStatus(this.eventID,true)
  },
  mounted() {
    // this.serverSideEvent()
    // this.serverSideEvent_StudentsStatus()
    // this.serverSideEvent_LeaveStudents()
  },
  unmounted(){
    this.SSE.studentStatus.close()
    this.SSE.studentLeave.close()
    clearInterval(this.updateInterval)
    clearInterval(this.updateStatsInterval)
  },
  beforeDestroy() {
    this.SSE.studentStatus.close()
    this.SSE.studentLeave.close()
    clearInterval(this.updateInterval)
    clearInterval(this.updateStatsInterval)

  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFunction)
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      },
    },
  },
  methods: {
    
    useZoom() {
      this.showZoomInput = !this.showZoomInput
      if (!this.showZoomInput) {
        this.eventZoomUrl = ""
      }
    },
    eventZoomUrlValidator(val) {
      if (val !== undefined) {
        let checkStr = val.includes("https://cmu-th.zoom.us/j/")
        let checkStartWith = val.startsWith("https://", 0)
        if (checkStr && checkStartWith) {
          this.eventZoomUrl = val
        }
        return checkStr && checkStartWith
      } else {
        this.eventZoomUrl = ""
        return false
      }
    },
    popup() {
      // console.log("this.eventid ", this.eventID)
      let route = this.$router.resolve({ path: `/events/${this.eventID}/map` })
      window.open(route.href, "_blank")
    },
    async exportXlsx() {
      // let printItem = this.itemsExam
      // let self = this
      // printItem.forEach(function(v) {
      //   delete v._id
      //   delete v.userid
      //   delete v.state_exam_courseid
      //   delete v.state_exam_name
      //   delete v.state_exam_mod
      //   delete v.state_exam_modid
      //   delete v.state_exam_status
      //   delete v.updated
      //   delete v.__v
      //   delete v.state_checkin
      //   for (let k = 0; k < self.newFieldExam.length; k++) {
      //     v[self.newFieldExam[k].label] = v[self.newFieldExam[k].key]
      //     delete v[self.newFieldExam[k].key]
      //   }
      // })
      let printArr = []
      Swal.fire({
        text: "กำลังประมวลผล กรุณารอสักครู่...",
      })
      Swal.showLoading()
      let resReportXlsx = await examManangerApi.getReportXlsxByEventId(this.eventID)
      Swal.close()
      // console.log("resReportXlsx:", resReportXlsx)
      if (resReportXlsx) {
        let printEachRow = []
        for (let i = 0; i < resReportXlsx["data"].length; i++) {
          printEachRow[0] = resReportXlsx["data"][i]["eventid"]
          printEachRow[1] = resReportXlsx["data"][i]["studentid"] ? resReportXlsx["data"][i]["studentid"] : ""
          printEachRow[2] = resReportXlsx["data"][i]["email"]
          printEachRow[3] = resReportXlsx["data"][i]["name"]
          printEachRow[4] = resReportXlsx["data"][i]["checkin_status"]
          printEachRow[5] =
            new Date(resReportXlsx["data"][i]["checkin_time_unix"] * 1000).toLocaleString("th-TH") !== "Invalid Date"
              ? new Date(resReportXlsx["data"][i]["checkin_time_unix"] * 1000).toLocaleString("th-TH")
              : ""
          printEachRow[6] = resReportXlsx["data"][i]["checkout_status"]
          printEachRow[7] =
            new Date(resReportXlsx["data"][i]["checkout_time_unix"] * 1000).toLocaleString("th-TH") !== "Invalid Date"
              ? new Date(resReportXlsx["data"][i]["checkout_time_unix"] * 1000).toLocaleString("th-TH")
              : ""
          printEachRow[8] = resReportXlsx["data"][i]["location_state"]
          printEachRow[9] = resReportXlsx["data"][i]["zoom_state"]
          printEachRow[10] =
            new Date(resReportXlsx["data"][i]["zoom_first_join_unix"] * 1000).toLocaleString("th-TH") !== "Invalid Date"
              ? new Date(resReportXlsx["data"][i]["zoom_first_join_unix"] * 1000).toLocaleString("th-TH")
              : ""
          printEachRow[11] =
            new Date(resReportXlsx["data"][i]["zoom_last_seen_unix"] * 1000).toLocaleString("th-TH") !== "Invalid Date"
              ? new Date(resReportXlsx["data"][i]["zoom_last_seen_unix"] * 1000).toLocaleString("th-TH")
              : ""
          printEachRow[12] = resReportXlsx["data"][i]["zoom_dropout_arr"].length
          printEachRow[13] = resReportXlsx["data"][i]["zoom_dropout_duration"]
          for (let j = 0; j < this.newFieldExam.length; j++) {
            for (let k = 0; k < resReportXlsx["data"][i]["quiz_status"].length; k++) {
              if (resReportXlsx["data"][i]["quiz_status"][k]["modid"].toString() === this.newFieldExam[j]["id"].toString()) {
                printEachRow.push(resReportXlsx["data"][i]["quiz_status"][k]["status"])
              }
            }
          }

          printArr.push(printEachRow)
          printEachRow = []
        }
        // console.log("printArr:", printArr)
      }

      let Heading = [
        [
          "Event ID",
          "Student ID",
          "Account",
          "Name",
          "Checkin Status",
          "Checkin Time",
          "Checkout Status",
          "Checkout Time",
          "Location",
          "Zoom State",
          "Zoom first joined",
          "Zoom last seen",
          "Zoom Dropouts (Count)",
          "Zoom Dropout Duration",
        ],
      ]

      for (let i = 0; i < this.newFieldExam.length; i++) {
        Heading[0].push(this.newFieldExam[i].label)
      }

      const dataWS = XLSX.utils.aoa_to_sheet(printArr, { origin: "A2" })
      const wb = XLSX.utils.book_new()
      XLSX.utils.sheet_add_aoa(dataWS, Heading)
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, `${this.eventName}.xlsx`)
    },
    showHelpAddRole() {
      Swal.fire({
        html: "<b>Host</b> - มีสิทธิ์สามารถทำได้ทุกอย่าง<br><b>Co-Host</b> - มีสิทธิ์ทุกอย่างเหมือน Host แต่ไม่สามารถลบ event ได้<br><b>Observer</b> - ดูได้เท่านั้น",
        showConfirmButton: false,
      })
    },
    showPincodeHelp() {
      Swal.fire({
        icon: "question",
        html:
          "<p class='text-left'><small><b>Pin code</b> เป็นทางสำรองของการเข้าสอบในกรณีที่นักศึกษาเข้าสอบผ่าน Link ปกติไม่ได้ (เช่น ได้รับ link ผิด หรือ ไม่สมบูรณ์)<br>อาจารย์สามารถแจ้งนักศึกษาให้เข้าใช้ระบบด้วย pin code ได้ ดังนี้<br><b>1.</b> เปิด link ต่อไปนี้<br><a href='https://exam-checkin.tlic.cmu.ac.th/' target='_blank'>https://exam-checkin.tlic.cmu.ac.th/</a><br><b>2.</b> ป้อน pin code ในหน้าต่างที่ปรากฏขึ้น</small></p>",
        showConfirmButton: false,
      })
    },
    scrollFunction() {
      let alertsticky = document.getElementById("alertsticky")
      let sticky = alertsticky.offsetTop
      if (window.pageYOffset > sticky) {
        alertsticky.classList.add("sticky")
      } else {
        alertsticky.classList.remove("sticky")
      }
    },
    getExamBadge(status) {
      switch (status) {
        case "finished":
          return "primary"
        case "inprogress":
          return "success"
        case "standby":
          return "warning"
        case "Banned":
          return "danger"
        case "new":
          return "success"
        case "draft":
          return "success"
        case "submitted":
          return "primary"
        default:
          return "primary"
      }
    },
    getZoomBadge(status) {
      switch (status) {
        case "Active":
          return "success"
        case "in_meeting":
          return "success"
        case "standby":
          return "warning"
        case "standby ":
          return "danger"
        case "waiting room":
          return "warning"
        case "disconnected":
          return "primary"
        case "disconnected ":
          return "danger"
        case "end meeting":
          return "primary"
        case "end meeting ":
          return "danger"
        default:
          return "primary"
      }
    },
    getZoomStatus(status) {
      switch (status) {
        case "end_meeting":
          return "end meeting"
        case "end_meeting ":
          return "end meeting "
        // case 'end meeting': return 'end meeting'
        // case 'end meeting ': return 'end meeting '
        case "standby":
          return "standby"
        case "standby ":
          return "standby "
        case "leave_meeting":
          return "disconnected"
        case "leave_meeting ":
          return "disconnected "
        case "in_meeting":
          return "in meeting"
        case "in-waiting-room":
          return "waiting room"
        default:
          return "no data"
      }
    },
    getCheckInBadge(status) {
      switch (status) {
        case "Check-In":
          return "success"
        case "Not Check-In":
          return "warning"
        case "Check-Out":
          return "primary"
        default:
          return "primary"
      }
    },
    getRoleStatus(role) {
      switch (role) {
        case "admin":
          return "Super-admin"
        case "owner":
          return "Host"
        case "assistant":
          return "Co-Host"
        case "observer":
          return "Observer"
        default:
          return "Not found"
      }
    },
    rowClicked(item, index) {
      this.$router.push({ path: `courses/${index + 1}` })
    },
    pageChange(val) {
      this.$router.push({ query: { id: this.eventID, page: val } })
    },
    paginationChange(val) {
      localStorage.setItem("itemPerPage", val)
    },
    sortChange(val) {
      // console.log(val)
      localStorage.setItem("sortItemRemember", JSON.stringify(val))
    },
    studentsReportClicked() {
      this.studentsReportModal = true
      this.notificationCounter = 0
      this.getStudentReport(this.eventID)
    },
    alertStudentLeave(item) {
      // console.log("alertStudentLeave")
      if (!item.state_checkout) {
        // console.log("All leave student:",this.temp_studentsLeave)
        if(!this.temp_studentsLeave.includes(item.name_TH)){
          this.notificationCounter++
          this.$toast.warning(`${item.name_TH} left zoom meeting`, {
            position: "top-right",
            duration: 5000,
          })
          this.temp_studentsLeave.push(item.name_TH)
          const index = this.temp_studentsLeave.length - 1
          const self = this
          setTimeout(function() {
            if (index > -1) {
              // console.log("clear leave student:",self.temp_studentsLeave[index])
              self.temp_studentsLeave.splice(index, 1)
              // console.log("All leave student:",self.temp_studentsLeave)
            }
          }, 1000)
        }
      }
    },
    async studentsReportFilter(reportList) {
      let reportArr = []
      let studentObj = new Object()
      for (let i = 0; i < reportList.length; i++) {
        studentObj.email = reportList[i].leave_user
        studentObj.leave_count = 1
        if (i > -1) {
          reportList.splice(i, 1)
        }
        for (let j = 0; j < reportList.length; j++) {
          if (studentObj.email === reportList[j].leave_user) {
            studentObj.leave_count++
            if (j > -1) {
              reportList.splice(j, 1)
            }
          }
        }
        reportArr.push(studentObj)
      }
      this.itemsReport = reportArr
    },
    async restartEventAvailable () {
      const deleteAvailable = await examManangerApi.deleteEventAvailable(this.eventID)
      if (deleteAvailable) {
        this.eventStart = false
        const createAvailable = await examManangerApi.createEventAvailable(this.eventID)
        if (createAvailable.status) {
          this.eventStart = true
        }
      }
    },
    async createEventAvailable(eventId) {
      if (this.accRole === "owner" || this.accRole === "assistant" || this.accRole === "admin") {
        let createAvailable = await examManangerApi.createEventAvailable(eventId)
        if (!createAvailable.status) {
          this.$toast.warning(`Quiz หรือ Assignment ถูกใช้งานแล้วใน Event: ${createAvailable["quiz-ready-exit"][0].examAvailable.name}`, {
            position: "top",
            duration: 3500,
          })
        } else if (createAvailable.status) {
          this.eventStart = true
        }
        return createAvailable
      } else {
        this.$toast.warning(`คุณไม่สามารถ Start/Stop event ได้`, {
          position: "top",
          duration: 1000,
        })
      }
    },
    async deleteEventAvailable(eventId) {
      let deleteAvailable = await examManangerApi.deleteEventAvailable(eventId)
      if (deleteAvailable) {
        this.eventStart = false
      }
      return deleteAvailable
    },
    async confirmDeleteEventAvailable(eventId) {
      Swal.fire({
        title: "คุณต้องการที่จะหยุดการสอบ?",
        text: "เมื่อหยุดการสอบแล้วค่าของนักศึกษาจะไม่ Realtime!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteEventAvailable(eventId)
        }
      })
    },
    async getStudentReportEachUser(email) {
      let userReport = await examManangerApi.getStudentReportEachUser(this.eventID, email)
      return userReport
    },
    async getStudentReport(eventId) {
      let report = await examManangerApi.getStudentReport(eventId)
      for (let user of report["data"]) {
        user.leave_ts = new Date(parseInt(user.leave_ts) * 1000).toLocaleString()
      }
      this.itemsReport = report["data"]
    },
    async checkIsOwner(eventId, email) {
      let eventList = await this.getEventListByOwner(email)
      let isOwner = false
      for (let event of eventList) {
        if (event._id === eventId) {
          isOwner = true
          // console.log("Yes is owner")
        }
      }
      if (!isOwner) {
        this.$router.push({ path: "/pages/404" })
      }
    },
    collaboratorEmailValidator(val) {
      // console.log(val)
      let returnData = false
      if (val !== undefined) {
        this.createCollaborator.email = val
        if (this.createCollaborator.email.includes("@cmu.ac.th")) {
          returnData = true
        } else {
          returnData = false
        }
      } else {
        returnData = false
      }
      if (val !== undefined) {
        if (this.createCollaborator.email.includes("@cmu.ac.th") && this.createCollaborator.role !== "") {
          this.createCollaborator.activeAddBtn = false
        } else {
          this.createCollaborator.activeAddBtn = true
        }
      }
      return returnData
    },
    collaboratorRoleValidator(val) {
      // // console.log(val)
      let returnData = false
      if (val !== undefined) {
        this.createCollaborator.role = val
        if (this.createCollaborator.role !== "") {
          returnData = true
        } else {
          returnData = false
        }
      } else {
        returnData = false
      }
      if (val !== undefined) {
        if (this.createCollaborator.email.includes("@cmu.ac.th") && this.createCollaborator.role !== "") {
          this.createCollaborator.activeAddBtn = false
        } else {
          this.createCollaborator.activeAddBtn = true
        }
      }
      return returnData
    },
    async submitCreateCollaborator() {
      // // console.log(this.createCollaborator)
      let data = {
        eventid: this.eventID,
        email: this.createCollaborator.email,
        roles: [this.createCollaborator.role],
      }
      const resCollaboratorRole = await examManangerApi.createCollaboratorRole(data)
      if (resCollaboratorRole.status) {
        await this.addCollaborator()
      }
    },
    async deleteCollaborator(item) {
      // // console.log(item)
      Swal.fire({
        title: "Are you sure?",
        text: `คุณต้องการลบ ${item.email} ใช่หรือไม่`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDelCollaboratorRole = await examManangerApi.deleteCollaboratorRole(this.eventID, item.email)
          if (resDelCollaboratorRole.status) {
            await this.addCollaborator()
          }
        }
      })
    },
    async checkIsCollaborator(eventId, email) {
      let isCollaborator = false
      let collaboratorRole = await examManangerApi.getCollaboratorRole(eventId, email)
      if (typeof collaboratorRole["data"] !== "undefined" && collaboratorRole["data"] !== false) {
        isCollaborator = true
        this.accRole = "observer"
        for (let role of collaboratorRole["data"]) {
          if (this.accRole !== "owner" || this.accRole !== "admin") {
            if (this.accRole !== "assistant" && role !== "observer") {
              this.accRole = role
            }
          }
        }
        // // console.log("role ", this.accRole)
      }
      if (!isCollaborator) {
        this.$router.push({ path: "/pages/nopermission" })
      }
    },
    async getEventListByOwner(email) {
      let eventList = await examManangerApi.getEventListByOwner(email)
      if (eventList) {
        this.eventList = eventList
        // // console.log("eventList(API):", eventList)
      }
      return eventList
    },
    async getEventByIdAndUpdate(eventId) {
      let event = await examManangerApi.getEventById(eventId)
      if (event) {
        this.eventData = event
      }
    },
    async getEventById(eventId) {
      let newFieldExam = { key: "", label: "" }
      let event = await examManangerApi.getEventById(eventId)
      this.fieldsExam = [
        { key: "index", label: "No." },
        { key: "name_TH", label: "Name", _classes: "font-weight-bold" },
        { key: "studentid", label: "รหัสนักศึกษา" },
        { key: "cmuitaccount", label: "CMU Account" },
        { key: "state_checkin", label: "Current Status" },
        { key: "checkin_ts", label: "Recent Status Update" },
        { key: "state_zoom", label: "Zoom Status" },
      ]
      // // console.log("event :", event)
      if (event) {
        this.eventName = event.eventName
        this.eventData = event
        for (let QuizOrAssign of event.srcExam.mod_exam_list) {
          newFieldExam = { key: "", label: "", id: "" }
          newFieldExam.id = QuizOrAssign.modid
          newFieldExam.key = `state_exam_${QuizOrAssign.mod}_${QuizOrAssign.modid}`
          newFieldExam.label = `ข้อสอบ: ${QuizOrAssign.name}`
          this.fieldsExam.push(newFieldExam)
          this.newFieldExam.push(newFieldExam)
          this.srcExamList = this.srcExamList.concat(QuizOrAssign)
        }
        this.srcExamFirst = this.srcExamList.shift()
        this.currentQuiz = this.srcExamFirst
        if (event.zoomMeeting.enable === false) {
          this.fieldsExam.splice(6, 1)
          this.noZoomInEvent = true
        }
        let fieldSetting = {
          key: "setting",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        }
        let note = {
          key: "note",
          label: "Note",
          width:"50px",
          // _style: "width:2%",
          sorter: false,
          filter: false,
        }
        this.fieldsExam.push(note)
        this.fieldsExam.push(fieldSetting)
        
      }
    },
    async getEventAvailableById(eventId) {
      let event = await examManangerApi.getEventAvailableById(eventId)
      if (event) {
        this.eventStart = true
        this.serverSideEvent_StudentsStatus()
        this.serverSideEvent_LeaveStudents()
      } else {
        this.eventStart = false
      }
    },
    async patchStudentLive(idx){
      console.log("idx :>", idx)
    
      const stdList = await examManangerApi.getStudentStatus(this.eventID)    
      const groupUsers = Object.groupBy(stdList, e => e.cmuitaccount);
      return await this.turnDataToShowInTable(this.itemsExam  ,groupUsers);
      
      // let idx = this.itemsExam.findIndex(obj =>
      //   obj.cmuitaccount === studentDetail["cmuitaccount"]
      // )
     
      // this.itemsExam[idx][`state_exam_${studentDetail["state_exam_mod"]}_${studentDetail["state_exam_modid"]}`]  = this.mappingStatusExam(studentDetail["state_exam_status"])
      // this.itemsExam[idx][`state_zoom`]  = studentDetail["state_zoom"]
      // this.itemsExam[idx][`state_checkin`]  =  studentDetail["state_checkin"]? (studentDetail["state_checkout"]? "Check-Out": "Check-In"): "Not Check-In"
      // this.itemsExam[idx][`checkin_ts`] =  studentDetail["checkin_ts"] === undefined ? "ยังไม่ได้เช็คอิน" : new Date(parseInt(studentDetail["checkin_ts"]) * 1000).toLocaleString("th-TH")
      // this.itemsExam[idx][`checkout_ts`] = studentDetail["checkout_ts"] === undefined ? "ยังไม่ได้เช็คเอาท์" : new Date(parseInt(studentDetail["checkout_ts"]) * 1000).toLocaleString("th-TH")

      
      // this.itemsExamAllQuiz = await this.itemsExamAllQuiz.map((obj) =>
      //   obj._id === studentDetail._id ?  { ...obj, ...studentDetail} : obj
      // )
      
      
    },
    // async patchStudentStats(){
    //   var chartStats = await examManangerApi.getChartInEventDetail(this.eventID);
    //   this.barChart = {
    //     checkIn: {
    //       checkOut: chartStats.data.checkin.checked_out,
    //       checkIn: chartStats.data.checkin.checked_in,
    //       notCheckIn: chartStats.data.checkin.not_checkin,
    //       all: chartStats.data.total,
    //     },
    //     zoom: {
    //       inZoom: chartStats.data.zoom.in_zoom,
    //       notInZoom: chartStats.data.zoom.not_in_zoom,
    //       endZoom: chartStats.data.zoom.end_zoom,
    //       disConnect: chartStats.data.zoom.disconnect,
    //       all: chartStats.data.total,
    //     },
    //     exam: {
    //       success:  chartStats.data.exam.finished,
    //       inprogress:  chartStats.data.exam.inprogress,
    //       notstart:  chartStats.data.exam.not_start,
    //       all:  chartStats.data.total,
    //     },
    //   }
    // },
    async getStudentStatus(eventId, showLoading = false) {
      
      
      if(showLoading){
        Swal.fire({
          text: "กำลังโหลด...",
        })
        Swal.showLoading()
      }
      var start = new Date().getTime()
      const stdList = await examManangerApi.getStudentStatus(eventId)    
      if (stdList) {
        let flattenUser = await this.initStudents(JSON.parse(JSON.stringify(stdList)));
        const groupUsers = Object.groupBy(stdList, e => e.cmuitaccount);
        this.itemsExam = await this.turnDataToShowInTable(flattenUser ,groupUsers)
        var end = new Date().getTime();
        var timeRefresh = (end - start )/ 1000.0
        console.log("init data finish" ,  (end - start )/ 1000.0)
        Swal.close()
        let changeData = 0
        // let checkInitStats = false
        // let checkInitLive  = false

        
        // this.updateStatsInterval = setInterval(async () => {
        //   if(this.threadCounter != changeData){
        //     var start = new Date().getTime()
        //     await this.patchStudentStats()
        //     var end = new Date().getTime();
        //     console.log("update patchStudent finish" ,  (end - start )/ 1000.0)
        //     checkInitStats = true
        //     if(checkInitLive && checkInitStats){
        //       Swal.close()
        //     }
        //   }
        // }, Number(1000*timeRefresh*2));
        this.updateInterval = setInterval(async () => {
          if(this.threadCounter != changeData){
            var start = new Date().getTime()
            changeData = this.threadCounter
            this.itemsExam = await this.patchStudentLive(this.threadCounter)
            var end = new Date().getTime();
            console.log("update patchStudentLive finish" ,  (end - start )/ 1000.0)
            // checkInitLive = true
           
          } 
          // if(checkInitLive){
          //   Swal.close()
          // }
        }, Number(1000*timeRefresh*2));


        
      }
    },
    initStudents(userArr){
      
      let quizs = {}

      for (let quizAssign of this.eventData.srcExam?.mod_exam_list) {
        quizs[`state_exam_${quizAssign.mod}_${quizAssign.modid}`] = "init"
      }

      return userArr.map(e => e['cmuitaccount'])
                      .map((e, i, final) => final.indexOf(e) === i && i)
                      .filter(obj=> userArr[obj])
                      .map((e) => {
                        let users = {}
                        delete userArr[e].state_exam_mod 
                        delete userArr[e].state_exam_modid 
                        delete userArr[e].state_exam_name 
                        users = { ...userArr[e] , ...quizs}
                        return users;
                      });
    },
    mappingStatusExam(status){
      switch (status){
        case "inprogress" :
          return "inprogress";
        case "new" :
          return "inprogress";
        case "draft" :
          return "inprogress";
        case "standby" :
          return "standby";
        case "finished" :
        // let updateStudentData = {
        //   eventid: this.eventID,
        //   field: "studentid",
        //   list: [filterUserArr[i].studentid],
        // }
        // if (!filterUserArr[i].state_finished) {
        //   examManangerApi.updateStudentFinishStatus(filterUserArr[i].cmuitaccount, this.eventID, updateStudentData)
        // }
          return "finished";
        case "submitted" :
          return "finished";
        default: 
          return "standby";

      }
    },
    // mappingStatusZoom(status){
    //   if (!this.noZoomInEvent) {
          
    //   }
    //   return status ; 
    // },

    _objectWithoutProperties(obj, keys) {
      var target = {};
      for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
      }
      return target;
    } ,
    async turnDataToShowInTable(userArr , data) {

      this.barChart = {
        checkIn: {
          checkOut: 0,
          checkIn: 0,
          notCheckIn: 0,
          all: 0,
        },
        zoom: {
          inZoom: 0,
          notInZoom: 0,
          endZoom: 0,
          disConnect:0,
          all: 0,
        },
        exam: {
          success:  0,
          inprogress:  0,
          notstart:  0,
          all: 0,
        },
      }

      let idx = 0 ;
      for (let key of Object.keys(data)) {
          // update data each exam field
          let checkExam = 0
          let checkExamTotal =  0 
          // console.log( userArr[idx][`cmuitaccount`])
          data[key].map((e) => {          
            let statusExam =  this.mappingStatusExam(e["state_exam_status"])
            // console.log(e["state_exam_status"])
            userArr[idx][`state_exam_${e["state_exam_mod"]}_${e["state_exam_modid"]}`]  = statusExam
            // console.log("status:>" , statusExam)
            checkExamTotal += 1
            statusExam !== "standby" ? statusExam !== "finished"? checkExam -= 1 : checkExam = checkExam + 999 : checkExam += 1 
          })
          // if(checkExam >= 900){
          //   console.log("check exam :> " , checkExamTotal , checkExam)
          // }
          if(checkExamTotal === checkExam){
            this.barChart.exam.notstart += 1
            if(data[key][0]["state_zoom"] === "end_zoom" ){
              this.barChart.zoom.endZoom += 1
            }else if(data[key][0]["state_zoom"] === "in_zoom" || data[key][0]["state_zoom"] === "in_meeting"){
              this.barChart.zoom.inZoom += 1
            }else if(data[key][0]["state_zoom"] === "not_in_zoom" || data[key][0]["state_zoom"] === "standby"){
              this.barChart.zoom.notInZoom += 1
            }else if(data[key][0]["state_zoom"] === "disconnected" || data[key][0]["state_zoom"] ==="leave_meeting" ){
              this.barChart.zoom.disConnect += 1
            }
          }else if(checkExam >= 900*checkExamTotal){
            this.barChart.exam.success += 1
            if(data[key][0]["state_zoom"] === "end_zoom" || data[key][0]["state_zoom"] ==="leave_meeting"){
              this.barChart.zoom.endZoom += 1
            }else if(data[key][0]["state_zoom"] === "in_zoom" || data[key][0]["state_zoom"] === "in_meeting"){
              this.barChart.zoom.inZoom += 1
            }else if(data[key][0]["state_zoom"] === "not_in_zoom" || data[key][0]["state_zoom"] === "standby"){
              this.barChart.zoom.notInZoom += 1
            }else if(data[key][0]["state_zoom"] === "disconnected"){
              this.barChart.zoom.disConnect += 1
            }
          }else{
            this.barChart.exam.inprogress += 1
            // if(data[key][0]["state_zoom"] === "not_in_zoom" || data[key][0]["state_zoom"] === "end_zoom" || data[key][0]["state_zoom"] === "disconnect" || data[key][0]["state_zoom"] === "standby"){
            //   this.barChart.zoom.disConnect += 1
            // }
            if(data[key][0]["state_zoom"] === "in_zoom" || data[key][0]["state_zoom"] === "in_meeting"){
              this.barChart.zoom.inZoom += 1
            }else if(data[key][0]["state_zoom"] === "not_in_zoom" || data[key][0]["state_zoom"] === "standby"){
              this.barChart.zoom.notInZoom += 1
            }else{
              this.barChart.zoom.disConnect += 1
            }
          }
         
          userArr[idx][`state_checkin`]  =  data[key][0]["state_checkin"]? (data[key][0]["state_checkout"]? "Check-Out" : "Check-In"): "Not Check-In"
          if(userArr[idx][`state_checkin`] === "Check-Out")
            this.barChart.checkIn.checkOut += 1
          else if(userArr[idx][`state_checkin`] === "Check-In"){
            this.barChart.checkIn.checkIn += 1
          }else{
            this.barChart.checkIn.notCheckIn += 1
          } 
          
          userArr[idx][`state_zoom`]  = data[key][0]["state_zoom"]
          if(data[key][0]["state_zoom"] === "disconnected" || data[key][0]["state_zoom"] === "end meeting" || data[key][0]["state_zoom"] ==="leave_meeting" ){
            userArr[idx]._classes = "table-danger"
          }else{
            delete userArr[idx]._classes
          }

          userArr[idx][`checkin_ts`] =  data[key][0]["checkin_ts"] === undefined ? "ยังไม่ได้เช็คอิน" : new Date(parseInt(data[key][0]["checkin_ts"]) * 1000).toLocaleString("th-TH")
          userArr[idx][`checkout_ts`] = data[key][0]["checkout_ts"] === undefined ? "ยังไม่ได้เช็คเอาท์" : new Date(parseInt(data[key][0]["checkout_ts"]) * 1000).toLocaleString("th-TH")
          idx += 1 ; 
      }
      
      this.barChart.exam.all = idx-1
      this.barChart.checkIn.all = idx-1
      this.barChart.zoom.all = idx-1
      
      return userArr;
    },

    async timeThaiFormat(timestamp) {
      let option = { year: "numeric", month: "long", day: "numeric", weekday: "long", hour: "numeric", minute: "numeric", second: "numeric" }
      return new Date(parseInt(timestamp) * 1000).toLocaleString("th-TH", option)
    },

    async eventInfo() {
      Swal.fire({
        html: `<b>ชื่อ Event:</b> ${this.eventData.eventName}<br>
                <b>เวลาเช็คอิน:</b> ${await this.timeThaiFormat(this.eventData.checkinTime)}<br>
                <b>เวลาเริ่ม:</b> ${await this.timeThaiFormat(this.eventData.startAt)}<br>
                <b>เวลาสิ้นสุด:</b> ${await this.timeThaiFormat(this.eventData.endAt)}<br>
                <b>CMU Exam:</b><br>${this.eventData.examLink}<br>
                <b>Zoom Meeting:</b><br>${this.eventData.zoomMeeting.meetingLink}<br>`,
        showConfirmButton: false,
      })
    },
    async confirmDelete(eventId) {
      Swal.fire({
        title: "คุณต้องการที่จะลบEvent?",
        text: "คุณจะไม่สามารถกู้ข้อมูลกลับได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteEvent(eventId)
        }
      })
    },
    async deleteEvent(eventId) {
      let deleteEvent = await examManangerApi.deleteEvent(eventId)
      if (deleteEvent) {
        Swal.fire("Deleted!", "Your Event has been deleted.", "success")
        this.$router.push({ path: "/events" })
      }
    },
    async changeTab(val) {
      this.currentQuiz = val
      this.getStudentStatus(this.eventID)
    },
    async studentDetail(item, index, columnName) {
      if (columnName !== "setting" &&  columnName !== "note") {
        Swal.fire({
          text: "กำลังโหลด...",
        })
        Swal.showLoading()
        const studentPhoto = await examManangerApi.getStudentPhoto(item.studentid)
        const studentProfile = await examManangerApi.getStudentProfile(item.cmuitaccount)
        this.studentPhoto = studentPhoto
        if (studentPhoto && studentProfile) {
          // console.log("studentProfile ", studentProfile)
          Swal.fire({
            html: `${item.name_TH}<br>` + `<b>รหัสนักศึกษา</b> ${item.studentid}<br>` + `<b>เบอร์โทรศัพท์</b> ${studentProfile["data"]["telephone"]}`,
            imageUrl: `data:image/jpeg;base64, ${studentPhoto}`,
            imageAlt: "Custom image",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: `<i class="fas fa-user-friends"></i> MS Teams Call`,
            confirmButtonColor: '#8565c4',
          }).then((result) => {
            if (result["isConfirmed"]) {
              window.open(`https://teams.microsoft.com/l/call/0/0?users=${studentProfile["data"]["cmuitaccount"]}`, "_blank").focus()
            }
          })
        }
      }
    },
    async addCollaborator() {
      let collaboratorList = await examManangerApi.getCollaboratorListByEventId(this.eventID)
      if (collaboratorList.status) {
        this.itemsCollaboratorList = collaboratorList.data
      }
      this.showAddCallaboratorModal = true
    },
    async submitUpdateStartEndDateTime() {},

    async addUser() {
      Swal.fire({
        title: "เพิ่มผู้สอบโดยแอดมิน",
        html: "<b>cmu account เท่านั้น</b>และหากต้องการเพิ่มมากกว่า 1 ให้คั่นด้วย comma (,)",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Add",
        showLoaderOnConfirm: true,
        preConfirm: (userList) => {
          let userListArr = userList.split(",")
          let type = "student"
          if (!userList.includes("_")) {
            type = "teacher"
          } else {
            Swal.showValidationMessage(`ไม่สามารถเพิ่มนักศึกษาได้`)
          }
          let jsonData = {
            type: type,
            eventid: this.eventID,
            userlist: userListArr,
          }
          return examManangerApi
            .createUserManual(jsonData)
            .then((response) => {
              // console.log(response)
              if (!response.data) {
                throw new Error("Fail")
              }
              return response
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("เสร็จสิ้น", "success")
          this.getStudentStatus(this.eventID)
        }
      })
    },

    async patchStudent(eventId){
      try{
        await examManangerApi.patchStudentsInEvents(eventId)
      }catch(e){
        // console.log("error patch student:"  , String(e))
      }
      this.getEventById(this.eventID)
      this.getStudentStatus(this.eventID,true)
    },

    async addStudent() {
      if (this.accRole === "owner" || this.accRole === "assistant" || this.accRole === "admin") {
        // console.log("add Student")
        Swal.fire({
          title: "เพิ่มนักศึกษา",
          html: "ใช้ <b>รหัสนักศึกษา หรือ CMU Account</b> เท่านั้น <br/>และหากต้องการเพิ่มมากกว่า 1 ให้คั่นด้วย comma (,)",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Add",
          showLoaderOnConfirm: true,
          preConfirm: (userList) => {
            userList = userList.replace(/\s/g, "")
            let userListArr = userList.split(",")
            userListArr = userListArr.filter((item) => item)
            let jsonData = JSON.parse("{}")
            jsonData["list"] = userListArr
            if (userList.includes("@cmu.ac.th") && /\d/.test(userList)) {
              Swal.showValidationMessage(`กรุณากรอกรหัสนักศึกษา หรือ CMU Account เพียงอย่างใดอย่างหนึ่ง`)
            } else if (userList.includes("@cmu.ac.th")) {
              jsonData["field"] = "cmuitaccount"
            } else if (/\d/.test(userList)) {
              jsonData["field"] = "studentid"
            } else {
              Swal.showValidationMessage(`กรุณากรอกข้อมูลให้ถูกต้อง`)
            }
            return examManangerApi
              .addStudents(this.eventID, jsonData)
              .then((response) => {
                // console.log(response)
                if (!response.data) {
                  throw new Error("Fail")
                }
                return response
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`)
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          // console.log("Result: ", result)
          if (result.isConfirmed) {
            Swal.fire("success", "เพิ่มนักศึกษาใน Exam Manager และ LMS เรียบร้อย", "success")
            this.getStudentStatus(this.eventID, true)
          }
        })
      } else {
        this.$toast.warning(`คุณไม่มีสิทธิในการเพิ่มนักศึกษา`, {
          position: "top",
          duration: 1000,
        })
      }
    },

    async deleteStudent(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `คุณต้องการลบนักศึกษา ${item.cmuitaccount} ใช่หรือไม่`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            text: "กำลังโหลด...",
          })
          Swal.showLoading()
          await examManangerApi.deleteStudents(this.eventID, item.cmuitaccount)
          this.getStudentStatus(this.eventID, true)
        }
      })
    },

    async sendExamResult() {
      if (this.accRole === "owner" || this.accRole === "assistant" || this.accRole === "admin") {
        // console.log("sendExamResult")
        let reportDataUpdate = {
          reportid: this.reportID,
          eventid: this.eventID,
          titlename: this.eventName,
          owner: localStorage.getItem("email"),
          detail: this.reportDetails,
        }
        let updateEventReport = await examManangerApi.updateEventReport(reportDataUpdate)
        // console.log("response:", updateEventReport)
        if (updateEventReport.status) {
          this.showSendExamResultModal = false
          Swal.fire({
            icon: "success",
            title: "ส่งรายงานการคุมสอบเรียบร้อย",
            showConfirmButton: false,
            timer: 1500,
          })
        }
      } else {
        this.$toast.warning(`คุณไม่มีสิทธิในการส่งรายงานได้`, {
          position: "top",
          duration: 1000,
        })
      }
    },

    async findStudentAndUpdate(incomingStudent) {
      // // console.log("incomingStudent:", incomingStudent)
      // // console.log("this.itemsExamAllQuiz:", this.itemsExamAllQuiz)
      let changingStudent = await examManangerApi.getUserFormSseMsg(this.eventID, incomingStudent.cmuitaccount)
      // // console.log("changingStudent:", changingStudent)
      for (let i = 0; i < changingStudent.length; i++) {
        let index = await this.itemsExamAllQuiz.findIndex((x) => x._id === changingStudent[i]._id && x.state_exam_modid === changingStudent[i].state_exam_modid)
        // // console.log("Index:", index)
        if (index > -1) {
          this.itemsExamAllQuiz[index] = changingStudent[i]
          // // console.log("change:", this.itemsExamAllQuiz[index], "to:", changingStudent[i])
        }
      }
      // console.log("this.itemsExamAllQuiz:", this.itemsExamAllQuiz)
      this.itemsExam = await this.turnDataToShowInTable(this.itemsExamAllQuiz)
      // console.log("this.itemsExam" , this.itemsExam)
      // this.turnDataToShowInTable(this.itemsExamAllQuiz)
    },

    async showSendExamResultModalFunc() {
      let eventReport = await examManangerApi.getReportWithEventId(this.eventID)
      // console.log(eventReport)
      this.reportID = eventReport.data._id
      this.getResultReport(eventReport.data._id)
      this.showSendExamResultModal = true
    },
    async getResultReport(reportId) {
      let checkinList = await examManangerApi.getStudentListWithStatus(reportId, "already")
      if (checkinList.status) {
        this.reportStatusList.ok = checkinList.data
      }
      let okList = await examManangerApi.getStudentListWithStatus(reportId, "completed")
      if (okList.status) {
        // this.reportStatusList.ok = okList.data
        this.reportStatusList.ok = this.reportStatusList.ok.concat(okList.data)
      }
      let absentList = await examManangerApi.getStudentListWithStatus(reportId, "idle")
      // console.log("idle", absentList)
      if (absentList.status) {
        this.reportStatusList.absent = absentList.data
      }
      let missList = await examManangerApi.getStudentListWithStatus(reportId, "miss")
      // console.log("miss", missList)
      if (missList.status) {
        this.reportStatusList.absent = this.reportStatusList.absent.concat(missList.data)
      }
      let lateList = await examManangerApi.getStudentListWithStatus(reportId, "late")
      if (lateList.status) {
        this.reportStatusList.late = lateList.data
      }
      let NotFoundList = await examManangerApi.getStudentListWithStatus(reportId, "none")
      if (NotFoundList.status) {
        this.reportStatusList.NotFound = NotFoundList.data
      }
      // console.log("this.reportStatusList", this.reportStatusList)
    },
    async editStudentreport(email) {
      if (this.accRole === "owner" || this.accRole === "assistant" || this.accRole === "admin") {
        Swal.fire({
          title: "แก้ไขเป็น?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonColor: "#00b900",
          cancelButtonColor: "#ffb647",
          confirmButtonText: "มาสอบปกติ",
          cancelButtonText: "มาสาย",
          denyButtonText: "ขาดสอบ",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // console.log("มาสอบปกติ", email)
            await examManangerApi.updateStudentReportStatus(this.reportID, email, "completed")
          } else if (result.isDismissed) {
            if (result.dismiss === Swal.DismissReason.cancel) {
              // console.log("มาสาย", email)
              await examManangerApi.updateStudentReportStatus(this.reportID, email, "late")
            }
          } else if (result.isDenied) {
            // console.log("ขาดสอบ", email)
            await examManangerApi.updateStudentReportStatus(this.reportID, email, "miss")
          }
          this.showSendExamResultModalFunc()
        })
      } else {
        this.$toast.warning(`คุณไม่มีสิทธิในการแก้ไข`, {
          position: "top",
          duration: 1000,
        })
      }
    },
    async detailFunc(val) {
      // console.log(val)
      this.reportDetails = val
    },
    async beforeShowSettingsModal() {
      // this.formSettings.checkInTime = await this.timeThaiFormat(this.eventData.checkinTime)
      let d = new Date(this.eventData.checkinTime * 1000)
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
      let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d)
      let da = new Intl.DateTimeFormat("th-TH", { day: "2-digit" }).format(d)
      let ho = new Intl.DateTimeFormat("th-TH", { hour: "numeric" }).format(d)
      let mi = new Intl.DateTimeFormat("th-TH", { minute: "numeric" }).format(d)
      this.formSettings.checkInDate = `${ye}-${mo}-${da}`
      this.formSettings.checkInTime = `${ho}:${mi < 10 ? "0" : ""}${mi}:00`
      d = new Date(this.eventData.startAt * 1000)
      ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
      mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d)
      da = new Intl.DateTimeFormat("th-TH", { day: "2-digit" }).format(d)
      ho = new Intl.DateTimeFormat("th-TH", { hour: "numeric" }).format(d)
      mi = new Intl.DateTimeFormat("th-TH", { minute: "numeric" }).format(d)
      this.formSettings.eventDate = `${ye}-${mo}-${da}`
      this.formSettings.eventStartTime = `${ho}:${mi < 10 ? "0" : ""}${mi}:00`
      d = new Date(this.eventData.endAt * 1000)
      ho = new Intl.DateTimeFormat("th-TH", { hour: "numeric" }).format(d)
      mi = new Intl.DateTimeFormat("th-TH", { minute: "numeric" }).format(d)
      this.formSettings.eventEndTime = `${ho}:${mi < 10 ? "0" : ""}${mi}:00`
      // console.log(this.formSettings)
      // Zoom
      this.eventZoomUrl = this.eventData.zoomMeeting.meetingLink
      this.showZoomInput = this.eventData.zoomMeeting.enable
      this.showSettingsModal = true
    },
    async submitUpdateCheckinDateTime() {
      // console.log("submitUpdateCheckinDateTime:", this.formSettings)
      // // console.log(this.toTimestamp(`${this.formSettings.checkInDate} ${this.formSettings.checkInTime}`))
      let checkInTimeArr = this.formSettings.checkInTime.split(":")
      if (checkInTimeArr.length == 2) {
        this.formSettings.checkInTime = `${this.formSettings.checkInTime}:00`
      }
      let newCheckInTime = this.toTimestamp2(`${this.formSettings.checkInDate} ${this.formSettings.checkInTime}`)
      // console.log("toTimestamp2", newCheckInTime)
      if (!isNaN(newCheckInTime)) {
        // console.log("Yes")
        let data = {
          eventid: this.eventID,
          checkInTime: newCheckInTime,
        }
        const resUpdateCheckInTime = await examManangerApi.updateCheckInTime(data)
        if (resUpdateCheckInTime.status) {
          await this.updateEventTime()
          // this.formSettings.showUpdateSuccess = true
          // setTimeout(() => this.formSettings.showUpdateSuccess = false, 3000)
          this.eventData.checkinTime = newCheckInTime
        } else {
          this.formSettings.showUpdateFail = true
          setTimeout(() => (this.formSettings.showUpdateFail = false), 3000)
        }
      } else {
        this.formSettings.showUpdateFail = true
        setTimeout(() => (this.formSettings.showUpdateFail = false), 3000)
      }
      // this.getEventByIdAndUpdate(this.eventID)
      this.updateEventZoom()
      setTimeout(() => this.$router.go(this.$router.currentRoute), 1500)
    },
    async updateEventTime() {
      // // console.log("updateEventTime:", this.formSettings)
      // // console.log(this.toTimestamp(`${this.formSettings.checkInDate} ${this.formSettings.checkInTime}`))
      let eventStartTimeArr = this.formSettings.eventStartTime.split(":")
      let eventEndTimeArr = this.formSettings.eventEndTime.split(":")
      if (eventStartTimeArr.length == 2) {
        this.formSettings.eventStartTime = `${this.formSettings.eventStartTime}:00`
      }
      if (eventEndTimeArr.length == 2) {
        this.formSettings.eventEndTime = `${this.formSettings.eventEndTime}:00`
      }
      let newStartTime = this.toTimestamp2(`${this.formSettings.eventDate} ${this.formSettings.eventStartTime}`)
      let newEndTime = this.toTimestamp2(`${this.formSettings.eventDate} ${this.formSettings.eventEndTime}`)
      // // console.log("toTimestamp2", newStartTime, ":", newEndTime)
      if (!isNaN(newStartTime) && !isNaN(newEndTime)) {
        // console.log("Yes")
        let data = {
          eventid: this.eventID,
          startAt: newStartTime,
          endAt: newEndTime,
        }
        const resUpdateEventTime = await examManangerApi.updateEventTime(data)
        if (resUpdateEventTime.status) {
          this.formSettings.showUpdateSuccess = true
          // setTimeout(this.formSettings.showUpdateSuccess = false, 3000)
          setTimeout(() => (this.formSettings.showUpdateSuccess = false), 3000)
          this.eventData.startAt = newStartTime
          this.eventData.endAt = newEndTime
        } else {
          this.formSettings.showUpdateFail = true
          setTimeout(() => (this.formSettings.showUpdateFail = false), 3000)
        }
      } else {
        this.formSettings.showUpdateFail = true
        setTimeout(() => (this.formSettings.showUpdateFail = false), 3000)
      }
    },
    async updateEventZoom() {
      let zoomIdArr = this.eventZoomUrl.split("/")
      let zoomId = zoomIdArr[zoomIdArr.length - 1]
      let zoomMeeting = {
        eventid: this.eventID,
        enable: this.showZoomInput,
        meetingid: zoomId,
        meetingLink: this.eventZoomUrl,
      }
      const resUpdateZoom = await examManangerApi.updateZoom(zoomMeeting)
      if (resUpdateZoom["status"]) {
        this.formSettings.showUpdateSuccess = true
        await this.restartEventAvailable()
        setTimeout(() => (this.formSettings.showUpdateSuccess = false), 3000)
      } else {
        this.formSettings.showUpdateZoomFail = true
        setTimeout(() => (this.formSettings.showUpdateZoomFail = false), 3000)
      }
    },
    // async onInputDateCheckIn(target) {
    //   // console.log(target)
    // },
    toTimestamp(strDate) {
      var datum = Date.parse(strDate)
      return datum / 1000
    },
    toTimestamp2(strDate) {
      // console.log("strDate:", strDate)
      let arr = strDate.split(/[- :]/),
        date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
      // console.log("arr:", arr)
      // console.log("date:", date)
      let returnData = date.getTime() / 1000
      return returnData
    },
    // serverSideEvent() {

    //   this.SSE.studentStatus = new EventSource(`${process.env.VUE_APP_SSE_DOMAIN}dashboard/quiz/status?eventid=${this.eventID}`)
    //   this.SSE.studentLeave = new EventSource(`${process.env.VUE_APP_SSE_DOMAIN}dashboard/leave?eventid=${this.eventID}`)

    //   this.SSE.studentStatus.onmessage = async (event) => {
    //     var data = JSON.parse(event.data)
    //     var msgParsed = JSON.parse(data.message)
    //     // // console.log("studentList(SSE):", msgParsed)
    //     this.findStudentAndUpdate(msgParsed)
    //   }
    //   this.SSE.studentLeave.onmessage = (event) => {
    //     var leaveData = JSON.parse(event.data)
    //     var leaveStudents = JSON.parse(leaveData.message)
    //     // // console.log("leaveStudent(SSE):", leaveStudents)
    //     this.alertStudentLeave(leaveStudents)
    //   }
    //   this.SSE.studentStatus.onerror = (event) => {
    //     // console.log(event)
    //     this.serverSideEvent()
    //   }
    // },
    serverSideEvent_StudentsStatus() {
      // const self = this
      this.SSE.studentStatus = new EventSource(`${process.env.VUE_APP_SSE_DOMAIN}dashboard/quiz/status?eventid=${this.eventID}`)
      this.SSE.studentStatus.onmessage = async () => {
        // var data = JSON.parse(event.data)
        // var msgParsed = JSON.parse(data.message)
        // this.patchStudentLive(msgParsed , this.threadCounter)
        this.threadCounter++ 
       
      }
      this.SSE.studentStatus.onerror = () => {
        console.log(this.SSE.studentStatus.readyState)
        if(this.SSE.studentStatus.readyState === 2){
          // console.log(event)
          this.serverSideEvent_StudentsStatus()
        }
      }
    },
    serverSideEvent_LeaveStudents() {
      this.SSE.studentLeave = new EventSource(`${process.env.VUE_APP_SSE_DOMAIN}dashboard/leave?eventid=${this.eventID}`)
      this.SSE.studentLeave.onmessage = (event) => {
        var leaveData = JSON.parse(event.data)
        var leaveStudents = JSON.parse(leaveData.message)
        // // console.log("leaveStudent(SSE):", leaveStudents)
        this.alertStudentLeave(leaveStudents)
      }
      this.SSE.studentLeave.onerror = () => {
        // console.log(this.SSE.studentLeave.readyState)
        if(this.SSE.studentLeave.readyState === 2){
          // console.log(event)
          this.serverSideEvent_LeaveStudents()
        }
      }
    },
  },
}
</script>
<style scoped>
.mag-settting-row {
  margin-top: 2%;
}

.update-settting {
  margin-top: 2%;
}
</style>
